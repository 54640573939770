export const menuItems = [
  {
    path: "/",
    text: "ホーム",
  },
  {
    path: "/about",
    text: "私たちについて",
  },
  {
    path: "/news",
    text: "お知らせ",
  },
  {
    path: "/contact",
    text: "お問い合わせ",
  },
]
